import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import Seo from "../components/utilities/seo";

const Category = ({
  data: {
    allWpPost: posts,
    wpCategory: category,
    wpPage: page,
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
  pageContext,
  location,
}) => {
  const banner = {
    name: "blog-banner",
    background: category.blog.background
      ? category.blog.background
      : page.blog.background,
    subTitle: category.blog.subTitle
      ? category.blog.subTitle
      : page.blog.subTitle,
    title: category.blog.title ? category.blog.title : page.blog.title,
    pageContext: pageContext,
    location: location,
  };

  return (
    <BlogElem banner={banner} pageContext={pageContext} location={location}>
      <Seo
        page={category.page}
        themeSettings={themeSettings}
        title={category.name}
        currentPage={currentPage}
      />
      <Posts
        posts={posts}
        totalPages={totalPages}
        currentPage={currentPage}
        baseUri={baseUri}
      />
    </BlogElem>
  );
};

export const pageQuery = graphql`
  query Category(
    $id: String!
    $perPage: Int!
    $skipItems: Int!
    $parentId: String!
  ) {
    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      edges {
        node {
          id
          title
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
          excerpt
        }
      }
    }
    wpCategory(id: { eq: $id }) {
      name
      blog {
        subTitle
        title
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
      page {
        metaTitle
        metaDescription
        pageCssOverrides
        pageSchema
      }
    }
    wpPage(id: { eq: $parentId }) {
      blog {
        subTitle
        title
        background {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          customCssClasses
        }
      }
    }
  }
`;

export default Category;
